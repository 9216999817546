h2, .logo {
    color: #00AB89;
    text-decoration: none;
}

.fa-dumbbell {
    position: relative;
    display: block;
    width: fit-content;
    margin: 0 auto;
}

.work-it-out-heading-container {
    height: 100px;
    margin-top: -10px;
    margin-left: 0px;
    background-color: rgba(0, 0, 0, 0.781);
    border-bottom: solid 1px gray;
    opacity: 1;
	width: 100vw;
}

.work-it-out-heading {
    text-align: center;
    margin-top: 10px;
    position: relative;
    top: 20px;
}

#avatar img {
	border-radius: 3px;
	height: 30px;
	object-fit: cover;
	width: 30px;
}

#user-display-name, #avatar {
	font-weight: bold;
	font-size: 1rem;
	line-height: 30px;
	margin-right: 0.5rem;
	display: inline-block;
	vertical-align: top;
}

.username-container {
	position: relative;
	bottom: 72px;
	width: fit-content;
	margin-left: calc(100vw - 115px);
}

.footer {
    width: 100%;
    text-align: center;
	margin-top: 15%;
}

.footer p {
	color: black;
}


@media only screen and (max-width: 500px) {

	#user-display-name, #avatar {
		font-size: .75rem;
	}

	.username-container {
		margin-left: calc(100vw - 90px);
	}
	
}

@media only screen and (max-width: 320px) {

    .work-it-out-heading-container {
        width: 113vw;
    }

    html {
        height: 125vh;
		}
		
	.username-container {
		margin-left: calc(100vw - 50px);
	}
 
 }