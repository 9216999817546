@import url(https://fonts.googleapis.com/css?family=Bungee+Inline);
@import url(https://fonts.googleapis.com/css?family=Lato);
* {
    color: white;
}

.hamburger-options-container {
    width: 15%;
    background-color: rgba(0, 0, 0, 0.781);
    height: 100vh;
    position: fixed;
    left: -15%;
    top: 101px;
    z-index: 20;
    opacity: 1;
    -webkit-transition: 1s;
    transition: 1s;
}

.hamburger-options-container.slide-in {
    left: 0%;
}

.link {
    text-decoration: none;
}

.hamburger-options-container .link:hover ,.hamburger-options-container.slide-in .link:hover {
    -webkit-text-decoration: underline overline #00AB89;
            text-decoration: underline overline #00AB89;
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
}
/* .link:hover, button:hover {

} */

.hamburger-all-container {
    position: relative;
    width: 15%;
    bottom: 156px;
}

button {
    margin-bottom: 25px;
    display: block;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 40px auto;
}

.hamburger {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 45px;
    margin-bottom: 50px;
    margin-left: 25px;
    cursor: pointer;
    position: relative;
    z-index: 5;
}

.hamburger div {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
  }

  @media only screen and (max-width: 500px) {

    button { 
       font-size: 14px; 
    }

    .hamburger-options-container {
        width: 40%;
        left: -40%;
    }
 
 }







.sign-in-out {
	font-weight: 700;
	color: #04424D;
	padding: 5px;
	background-color: rgba(228, 225, 225, 0.993);
	width: 10%;
	height: 3%;
	border: solid 1px gray;
	border-radius: 20px;
	margin: 0 auto;
	cursor: pointer;
	-webkit-transition: .5s;
	transition: .5s;
}

.sign-up:hover {
	-webkit-transform: scale(1.02);
	        transform: scale(1.02);
	box-shadow: 0px 5px;
}


@media only screen and (max-width: 500px) {

	.sign-in-out { 
			width: 28%; 
	}

	#user-display-name, #avatar {
		font-size: .75rem;
	}

}
body {
	height: auto;
	font-family: 'Lato';
	width: 100vw;
	background-image: url(/static/media/gym-wallpapers-6.cfe81449.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 75% 50%;
	color: white;
}

.landing-page-container.fade-back {
	opacity: .5;
}

.landing-page-container {
	display: block;
	text-align: center;
	padding-top: 100px;
	width: 100vw;
	opacity: 1;
	-webkit-transition: 1s;
	transition: 1s;
}

.work-it-out-body {
	margin-top: 0px;
	position: relative;
	width: 100%;
	height: 200px;
}

.what-is {
	width: 400px;
	height: 300px;
	margin: 0 auto;
	border-radius: 7px;
	background-color: rgb(255, 255, 255);
	border: 1px solid #00AB89;
}

.what-is p {
	color: black;
}

.steps {
	opacity: 1;

}

h2 {
	font-size: 2em;
}

h3 {
	margin: 0;
	color: #00AB89;
	font-weight: 500;
	font-size: 1.5em;
}

.step-zero {
	width: 100%;
	height: 225px;
	margin-top: 50px;
}

.step-zero p {
	font-size: 1.7em;

}

.step-images {
	height: 150px;
	border-radius: 10px;
	width: 100%;
	opacity: 1.5;
}

div .step-two-image, div .step-three-image {
	margin-top: 25px;
	border-radius: 5px;
}

.step-two-image video {
	height: 200px;
	padding-bottom: 50px;
}

.step-three-image video {
	height: 200px;
}

.step-one-image, .step-one-body, .step-two-image, .step-two-body, .step-three-image, .step-three-body {
	width: 50%;
	display: inline-block;
}

.step-one p, .step-two p, .step-three p {
	font-size: 1.3em;
}

.step-one h2 {
	margin: 0;
	margin-bottom: 20px;
	padding-top: 10px;
}

.work-it-out-body p {
	font-size: 3em;
}

.step-one p, .step-three p {
	color: black;
}

.step-one-body {
	vertical-align: top;
}

.step-one-image {
	margin-top: 20px;
}

.step-one {
	width: 100%;
	background-color: rgb(255, 255, 255);
	height: 250px;
}

.step-two-body {
	vertical-align: top;
}

.step-two-image {
	margin-top: 50px;
}

.step-two {
	width: 100%;
	background-color: rgba(0, 0, 0, 0.452);
	height: 250px;
}

.step-three-body {
	vertical-align: top;
}

.step-three-image {
	margin-top: 50px;
}

.step-three {
	width: 100%;
	background-color: rgb(255, 255, 255);
	height: 250px;
	margin-bottom: 100px;
}

@media only screen and (max-width: 500px) {

	.what-is {
		width: 350px;
	}

}

@media only screen and (max-width: 320px) {

	.what-is {
		width: 350px;
	}

	html {
		background-size: auto;
	}

}



.addworkout-form {
    padding: 2%;
    padding-bottom: 0;
    padding-top: 0;
    margin: 0 auto;
    display: block;
    width: 6%;
    height: 375px;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    position: relative;
    top: calc(8vh - 500px);
    z-index: -5;
    border-radius: 5px;
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    overflow-y: scroll;
    overflow-x: none;
}

.addworkout-form.fade {
    opacity: 1;
    z-index: 5;
}

.addworkout-form label {
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 5px auto;
    display: block;
}

.addworkout-form input {
    margin: 0 auto 4% auto;
    color: black;
    width: 80%;
}

input[type = submit] {
    background-color: #81BDA4;
    color: #FFF;
    text-align: center;
    width: 50%;
    border:0;
    padding: 1%;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    margin: 15% auto;
    font-size: .5em;
  }

.exit-workout-display {
    color: black;
    margin-bottom: 17%;
    margin-top: 2%;
    margin-left: calc(100% - 10px);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

@media only screen and (max-width: 1450px) {

    .addworkout-form {
        width: 10%;
    }
}

@media only screen and (max-width: 800px) {

    .addworkout-form {
        width: calc(100vw - 80vw);
    } 

}


.calendar-container {
    display: block;
    margin: 50px auto;
    height: 500px;
    width: 1000px;
    position: relative;
}

h2 {
    text-align: center;
}

ul {
    list-style: none;
    height: calc(100% - 75px);
    width: 100%;
    padding-left: 0;
}

.title-chest, .title-shoulders, .title-biceps, .title-triceps, .title-abs, .title-back {
    background-color: #39646B;
}

.title-legs, .title-glutes {
    background-color: #B8733D;
}

.rbc-day-bg.rbc-today {
    background-color:  rgb(125, 204, 188);
}

.rbc-toolbar {
    display: block;
    margin: 0 auto;
}

.rbc-btn-group {
    display: block;
    margin-bottom: 8%;
}

.rbc-btn-group button {
    color: #00AB89;
}

.rbc-toolbar-label {
    margin-left: calc(22vw - 55px);
    margin-left: 3vw;
}


@media only screen and (max-width: 1000px) {

    .calendar-container {
        width: 700px;
    }

    .rbc-toolbar-label {
        margin-left: 5vw;
    }
}

@media only screen and (max-width: 800px) {

    .calendar-container {
        width: 550px;
        height: 550px;
    }
}

@media only screen and (max-width: 500px) {

    .calendar-container {
        width: 365px;
        height: 550px;
    }

    .rbc-toolbar-label {
        margin-left: 9vw;
    }

}

@media only screen and (max-width: 500px) {

    .rbc-toolbar-label {
        margin-left: 8vw;
    }

}

@media only screen and (max-width: 360px) {

    .calendar-container {
        width: 320px;
        height: 525px;
        margin-left: 20px;
    }

    .rbc-btn-group button {
        margin-top: 10%;
    }

    .rbc-toolbar-label {
        margin-left: 10vw;
    }

}


input {
    display: block;
}

.workouts-container {
    padding: 2%;
    padding-bottom: 0;
    padding-top: 0;
    margin: 0 auto;
    display: block;
    width: 5%;
    height: 375px;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    position: relative;
    top: calc(8vh - 500px);
    z-index: -5;
    border-radius: 5px;
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    overflow-y: scroll;
    overflow-x: none;
}

.workouts-container.fade {
    opacity: 1;
    z-index: 5;
}

.workouts {
    color: black;
    display: block;
    width: 100%;
    margin: 0 auto 25% auto;
}

.workouts button {
    margin: 0 auto;

}

.workouts div {
    text-align: center;
    margin: 4% auto;
    width: 100%;
    color: black;
}

div .workout-title {
    margin-bottom: 10%;
    color: #00AB89;
    font-weight: 500;
}

.exit-workout-display {
    margin-bottom: 17%;
    margin-top: 2%;
    margin-left: calc(100% - 10px);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.delete-button {
    margin: 8%;
    background-color: #81BDA4;
    color: #FFF;
    text-align: center;
    width: 50%;
    border: 0;
    padding: 2%;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    margin: 15% auto;
    font-size: 10px;
}


@media only screen and (max-width: 1450px) {

    .workouts-container {
        width: 10%;
    }
}

@media only screen and (max-width: 800px) {

    .workouts-container {
        width: calc(100vw - 80vw);
    } 

}
h2, .logo {
    color: #00AB89;
    text-decoration: none;
}

.fa-dumbbell {
    position: relative;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.work-it-out-heading-container {
    height: 100px;
    margin-top: -10px;
    margin-left: 0px;
    background-color: rgba(0, 0, 0, 0.781);
    border-bottom: solid 1px gray;
    opacity: 1;
	width: 100vw;
}

.work-it-out-heading {
    text-align: center;
    margin-top: 10px;
    position: relative;
    top: 20px;
}

#avatar img {
	border-radius: 3px;
	height: 30px;
	object-fit: cover;
	width: 30px;
}

#user-display-name, #avatar {
	font-weight: bold;
	font-size: 1rem;
	line-height: 30px;
	margin-right: 0.5rem;
	display: inline-block;
	vertical-align: top;
}

.username-container {
	position: relative;
	bottom: 72px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin-left: calc(100vw - 115px);
}

.footer {
    width: 100%;
    text-align: center;
	margin-top: 15%;
}

.footer p {
	color: black;
}


@media only screen and (max-width: 500px) {

	#user-display-name, #avatar {
		font-size: .75rem;
	}

	.username-container {
		margin-left: calc(100vw - 90px);
	}
	
}

@media only screen and (max-width: 320px) {

    .work-it-out-heading-container {
        width: 113vw;
    }

    html {
        height: 125vh;
		}
		
	.username-container {
		margin-left: calc(100vw - 50px);
	}
 
 }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

