.sign-in-out {
	font-weight: 700;
	color: #04424D;
	padding: 5px;
	background-color: rgba(228, 225, 225, 0.993);
	width: 10%;
	height: 3%;
	border: solid 1px gray;
	border-radius: 20px;
	margin: 0 auto;
	cursor: pointer;
	transition: .5s;
}

.sign-up:hover {
	transform: scale(1.02);
	box-shadow: 0px 5px;
}


@media only screen and (max-width: 500px) {

	.sign-in-out { 
			width: 28%; 
	}

	#user-display-name, #avatar {
		font-size: .75rem;
	}

}